import logo from './logo.png';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const firebaseConfig = {
  apiKey: "AIzaSyBqeKvvvw8FMzWIFerKZJRwjM5Sz3w5vj0",
  authDomain: "rethinker-d15bb.firebaseapp.com",
  projectId: "rethinker-d15bb",
  storageBucket: "rethinker-d15bb.appspot.com",
  messagingSenderId: "406569553852",
  appId: "1:406569553852:web:16ce65e697ec277a2da5b9",
  measurementId: "G-10FV7VPCMD"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <body className="App-body">
        {/* <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">Employment Relations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" align="left">
                ▷ people policies, procedures, guidelines and templates
              </Typography>
              <Typography variant="body1" align="left">
                ▷ conduct, performance, capacity and complex cases
              </Typography>
              <Typography variant="body1" align="left">
                ▷ compliance audits
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5">Industrial Relations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" align="left">
                ▷ enterprise bargaining
              </Typography>
              <Typography variant="body1" align="left">
                ▷ industrial disputes
              </Typography>
              <Typography variant="body1" align="left">
                ▷ Fair Work Commission applications and representation
              </Typography>
              <Typography variant="body1" align="left">
                ▷ workplace investigations
              </Typography>
              <Typography variant="body1" align="left">
                ▷ forensic wage compliance investigations
              </Typography>
              <Typography variant="body1" align="left">
                ▷ Government submissions
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">People Data Analytics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" align="left">
                ▷ industrial and workplace policy analysis
              </Typography>
              <Typography variant="body1" align="left">
                ▷ remuneration and benefits
              </Typography>
              <Typography variant="body1" align="left">
                ▷ organisational design and workforce planning
              </Typography>
              <Typography variant="body1" align="left">
                ▷ WGEA and other general compliance reporting
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5">People Professional for Hire</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" align="left">
                ▷ human resources director for hire
              </Typography>
              <Typography variant="body1" align="left">
                ▷ employment and industrial relations director for hire
              </Typography>
              <Typography variant="body1" align="left">
                ▷ "white label" services
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5">Research, Projects and Change</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" align="left">
                ▷ diversity and inclusion
              </Typography>
              <Typography variant="body1" align="left">
                ▷ shared paid parental leave
              </Typography>
              <Typography variant="body1" align="left">
                ▷ major change, restructure and redundancy
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div>
          <Typography variant="body1" align="left" style={{ fontSize: '50%' }}>
            WisCa Pty Ltd trading as Rethink ER
          </Typography>
        </div> */}
      </body>
    </div>
  );
}

export default App;
